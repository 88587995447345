import { Table } from '@interstate/components/Table'
import React, { useEffect, useState } from 'react'
import {
    getVehicleAuctionNotes,
    getVehicleAuctionSblu
} from '../../service/auctionVehicleDataService'
import { getPossibleFeeSchedules } from '../../service/feeScheduleService'
import { FeeStructureColumn } from '../FeeStructureColumn'
import { Grid } from '@interstate/components/Grid'
import { VehicleNotesTable } from '../VehicleNotesTable'
import { PossibleFeeTable } from '../PossibleFeeTable'

export function IndividualAudit({ data }) {
    const [initData, setInitData] = useState(null)
    const [vehicleData, setVehicleData] = useState({})
    const [feeSchedules, setFeeSchedules] = useState([])
    const [highlightedRow, setHighlightedRow] = useState([])

    useEffect(() => {
        if (data && data.length > 0) {
            setInitData(data[0])
        }
    }, [data])

    useEffect(() => {
        if (!initData) return
        setInitData(data[0])
        let av = initData?.buCode
        let sblu = initData?.sblu
        getVehicleAuctionSblu(av, sblu)
            .then((d) => {
                setVehicleData(d)
            })
            .catch((error) => {
                setVehicleData(error.response.data)
            })

        let highlight = []

        data.forEach((fee) => {
            if (!highlight.includes(fee.feeSchedulePartId)) {
                highlight.push(fee.feeSchedulePartId)
            }
        })
        setHighlightedRow(highlight)
    }, [initData, data])

    useEffect(() => {
        if (!initData) return

        let av = initData?.buCode

        let customerIds = [
            initData?.seller,
            initData?.buyer,
            initData?.sellerGroup,
            initData?.buyerGroup,
            initData?.sellerSubGroup,
            initData?.buyerSubGroup
        ]
        if (vehicleData?.body?.saleDate) {
            getPossibleFeeSchedules(
                av,
                customerIds,
                initData.saleType,
                vehicleData?.body?.saleDate
            )
                .then((d) => {
                    setFeeSchedules(d)
                })
                .catch((error) => {
                    setFeeSchedules(error?.response?.data)
                })
        }
    }, [initData, vehicleData])
    console.log(vehicleData)
    return (
        <>
            <Grid container spacing={2}>
                {initData && (
                    <Grid xs={12} md={3}>
                        <div>
                            <b>Auction:</b>
                            {initData.buCode}
                        </div>
                        <div>
                            <b>SBLU:</b> {initData.sblu}
                        </div>
                        <div>
                            <b>Work Order:</b> {initData.workOrderNumber}
                        </div>
                        <div>
                            <b>Universal Key:</b>
                            {initData?.saleYear}-{initData?.saleNumber}-
                            {initData?.lane}-{initData?.runNumber}
                        </div>
                    </Grid>
                )}
                <Grid xs={12} md={3}>
                    {vehicleData?.body && (
                        <div>
                            <div>
                                <b>BUYER:</b> {vehicleData.body.buyer}
                            </div>
                            <div>
                                <b>BUYER Group:</b>{' '}
                                {vehicleData.body.buyerGroup}{' '}
                                {vehicleData.body.buyerSubGroup}
                            </div>
                            <div>
                                <b>BUYER L/T:</b> {vehicleData.body.lane} /{' '}
                                {vehicleData.body.buyerFeeTable}
                            </div>
                            <div>
                                <b>BUYER fee:</b> {vehicleData.body.buyerFee}
                            </div>
                        </div>
                    )}
                </Grid>
                <Grid xs={12} md={3}>
                    {vehicleData?.body && (
                        <div>
                            <div>
                                <b>SELLER:</b> {vehicleData.body.seller}
                            </div>
                            <div>
                                <b>SELLER Group:</b>{' '}
                                {vehicleData.body.sellerGroup}{' '}
                                {vehicleData.body.sellerSubGroup}{' '}
                            </div>
                            <div>
                                <b>SELLER L/T:</b> {vehicleData.body.lane} /{' '}
                                {vehicleData.body.sellerFeeTable}
                            </div>
                            <div>
                                <b>SELLER fee:</b> {vehicleData.body.sellerFee}
                            </div>
                        </div>
                    )}
                </Grid>
                <Grid xs={12} md={3}>
                    {vehicleData?.body && (
                        <div>
                            <div>
                                <b>VIN:</b> {vehicleData.body.vin}
                            </div>
                            <div>
                                <b>Sale Price:</b> {vehicleData.body.salePrice}
                            </div>
                            <div>
                                <b>Sale Date:</b> {vehicleData.body.saleDate}
                            </div>
                            <div>
                                <b>Channel:</b> {vehicleData.body.saleChannel}
                            </div>
                        </div>
                    )}
                </Grid>
            </Grid>
            <div>
                <h2>Calculated Fees</h2>
                <Table
                    tableLayout={'auto'}
                    columns={[
                        {
                            title: 'Fee Category',
                            render: (item) => <span>{item.feeCategory}</span>
                        },
                        {
                            title: 'Sale Type',
                            render: (item) => <span>{item.saleType}</span>
                        },
                        {
                            title: 'Fee Schedule Type',
                            render: (item) => <span>{item.contractType}</span>
                        },
                        {
                            title: 'Fee Type',
                            render: (item) => <span>{item.feeType}</span>
                        },
                        {
                            title: 'Pat Fee',
                            render: (item) => (
                                <span>{item.patCalculatedFee}</span>
                            )
                        },
                        {
                            title: 'AS400 Fee',
                            render: (item) => (
                                <span>{item.as400CalculatedFee}</span>
                            )
                        },
                        {
                            title: 'FeeID',
                            render: (item) => (
                                <span>{item.feeSchedulePartId}</span>
                            )
                        }
                    ]}
                    data={data}
                />

                <div>
                    <h2>Eligible Seller Fees</h2>
                    <PossibleFeeTable
                        fees={feeSchedules}
                        filterCriteria={['SSELL', 'SELLER', 'SLIST']}
                        highlightedRow={highlightedRow}
                    />
                </div>

                <div>
                    <h2>Eligible Buyer Fees</h2>
                    <PossibleFeeTable
                        fees={feeSchedules}
                        filterCriteria={[
                            'BUYER',
                            'BLIST',
                            'BBUY',
                            'SELLBUY',
                            'SELLBYS'
                        ]}
                        highlightedRow={highlightedRow}
                    />
                </div>

                {vehicleData?.body?.saleChannel === 'Z' && (
                    <div>
                        <h2>Eligible Simulcast Fees</h2>
                        <PossibleFeeTable
                            fees={feeSchedules}
                            filterCriteria={[
                                'INTBUY',
                                'INTSELL',
                                'SIMBFE',
                                'SIMBUY'
                            ]}
                            highlightedRow={highlightedRow}
                        />
                    </div>
                )}

                {initData && (
                    <div>
                        <h2>Vehicle Notes</h2>
                        <VehicleNotesTable
                            auction={initData?.buCode}
                            workOrderNumber={initData.workOrderNumber}
                        />
                    </div>
                )}
            </div>
        </>
    )
}
