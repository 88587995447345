import { FeeStructureColumn } from './FeeStructureColumn'
import { Table } from '@interstate/components/Table'
import React, { useEffect } from 'react'

export function PossibleFeeTable({ fees, filterCriteria, highlightedRow }) {
    const [feeSchedules, setFeeSchedules] = React.useState([])

    useEffect(() => {
        let fs = fees.filter((fee) => {
            return filterCriteria.includes(fee.feeType)
        })
        setFeeSchedules(fs)
    }, [fees, filterCriteria])

    return (
        <Table
            tableLayout={'auto'}
            enablePagination={false}
            rowClassName={(item) => {
                let reply = 'tableGood'
                if (highlightedRow.includes(item.id)) {
                    reply = 'tableHighlight'
                }
                return reply
            }}
            columns={[
                {
                    title: 'Sale Type',
                    render: (item) => <span>{item.saleType}</span>
                },
                {
                    title: 'Fee Schedule Type',
                    render: (item) => <span>{item.contractType}</span>
                },
                {
                    title: 'Fee Type',
                    render: (item) => <span>{item.feeType}</span>
                },
                {
                    title: 'Customer',
                    render: (item) => <span>{item.customer}</span>
                },
                {
                    title: 'Fee Structure',
                    render: (item) => (
                        <span>
                            <FeeStructureColumn
                                feeStructure={item.feeStructure}
                            />
                        </span>
                    )
                },
                {
                    title: 'Description',
                    render: (item) => <span>{item.description}</span>
                },
                {
                    title: 'Start Date',
                    render: (item) => <span>{item.startDate}</span>
                },
                {
                    title: 'End Date',
                    render: (item) => <span>{item.endDate}</span>
                },
                {
                    title: 'FeeID',
                    render: (item) => <span>{item.id}</span>
                }
            ]}
            data={feeSchedules}
        />
    )
}
