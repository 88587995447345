import { LocationComboBox } from '../common/LocationComboBox'
import { Grid } from '@interstate/components/Grid'
import { PatTextInput } from '../common/PatTextInput'
import { Button } from '@interstate/components/Button'
import { React, useState } from 'react'
import { padAltGroupCode } from '../../utils/customer.helper.util'
import { useAuth } from '../../auth/AuthProvider'

function firstLocation(locationId) {
    if (locationId && locationId.trim().length > 0) {
        return [locationId?.split(',')[0]]
    }
    return null
}

export function SearchTransactionForm({ search }) {
    const authService = useAuth()
    const [universalKey, setUniversalKey] = useState(null)
    const [locationId, setlocationId] = useState(
        firstLocation(authService?.principal?.locationId) || null
    )
    const [workOrderNumber, setWorkOrderNumber] = useState(null)
    const [vin, setVin] = useState(null)
    const [account, setAccount] = useState(null)
    const [groupCode, setGroupCode] = useState(null)
    const [altGroupCode, setAltGroupCode] = useState(null)

    const handleChange = (e) => {
        const { name, value } = e.target
        let newValue = value
        if (typeof newValue === 'string') {
            newValue = newValue.trim()
        }
        if (newValue.length === 0) {
            newValue = null
        }
        switch (name) {
            case 'universalKey':
                setUniversalKey(newValue)
                break
            case 'workOrderNumber':
                setWorkOrderNumber(newValue)
                break
            case 'vin':
                setVin(newValue)
                break
            case 'account':
                setAccount(newValue)
                break
            case 'groupCode':
                setGroupCode(newValue.toUpperCase())
                break
            case 'altGroupCode':
                setAltGroupCode(newValue.toUpperCase())
                break
        }
    }

    const removeNulls = (obj) => {
        let newObj = {}
        Object.keys(obj).forEach((key) => {
            if (obj[key] !== null) {
                newObj[key] = obj[key]
            }
        })
        return newObj
    }

    const handleSearch = (e) => {
        e.preventDefault()
        if (null != vin && vin.length < 6) {
            return
        }
        let query = {
            universalKey,
            locationId: locationId?.map((loc) => loc.value) || null,
            workOrder: workOrderNumber,
            vin,
            accounts: account?.split(',') || null,
            groupCodes: groupCode?.split(',') || null,
            altGroupCodes:
                altGroupCode?.split(',').map((val) => padAltGroupCode(val)) ||
                null,
            testSource: 'vehicle-fee-processor-lambda'
        }
        search(removeNulls(query))
    }
    return (
        <form>
            <LocationComboBox
                name="locationId"
                value={locationId}
                onChange={(e) => {
                    setlocationId(e.target.value)
                }}
                auctionEditFilter={true}
                multiple={false}
            />
            <Grid container spacing={2}>
                <Grid xs={12} md={4}>
                    <PatTextInput
                        name={'universalKey'}
                        label="Universal Key"
                        masking={{
                            guide: true,
                            mask: [
                                /\d/,
                                /\d/,
                                /\d/,
                                /\d/,
                                '-',
                                /\d/,
                                /\d/,
                                /\d/,
                                '-',
                                /\d/,
                                /\d/,
                                '-',
                                /\d/,
                                /\d/,
                                /\d/,
                                /\d/
                            ],
                            pipe: undefined,
                            unmask: []
                        }}
                        value={universalKey}
                        onChange={handleChange}
                    />
                </Grid>
                <Grid xs={12} md={4}>
                    <PatTextInput
                        name={'workOrderNumber'}
                        label="Work Order Number"
                        value={workOrderNumber}
                        onChange={handleChange}
                    />
                </Grid>
                <Grid xs={12} md={4}>
                    <PatTextInput
                        name={'vin'}
                        label="VIN"
                        value={vin}
                        onChange={handleChange}
                        minLength={6}
                    />
                </Grid>
                <Grid xs={12} md={4}>
                    <PatTextInput
                        name={'account'}
                        label="Account"
                        value={account}
                        onChange={handleChange}
                    />
                </Grid>
                <Grid xs={12} md={4}>
                    <PatTextInput
                        name={'groupCode'}
                        label="Group Code"
                        value={groupCode}
                        onChange={handleChange}
                    />
                </Grid>
                <Grid xs={12} md={4}>
                    <PatTextInput
                        name={'altGroupCode'}
                        label="Alt Group Code"
                        value={altGroupCode}
                        onChange={handleChange}
                    />
                </Grid>
            </Grid>
            <Button buttonStyle={'primary'} onClick={handleSearch}>
                Search
            </Button>
        </form>
    )
}
